body {
  margin: 0;
  font-family: 'Montserrat';
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Pricing table style */
.main {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    font-family: "Open Sans";
    width: 1170px;
    margin: 0 auto;
}
.price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
}
.price-table tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 14px;
}
.price-table tr td:first-child {
    border-left: 0 none;
}
.price-table tr td:not(:first-child) {
    text-align: center;
}
.price-table tr:nth-child(even) {
    background-color: #FFFFFF;
}
.price-table tr:hover {
    background-color: #EEEEEE;
}
.price-table .fa-check {
    color: #7eb022;
}
.price-table .fa-times {
    color: #D8D6E3;
}

/* Highlighted column */
/* .price-table tr:nth-child(2n) td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.25);
} */
/* .price-table tr td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.15);
    padding: 8px 48px;
} */
.price-table tr td:nth-child(3) .fa-check,
.price-table tr:nth-child(2n) td:nth-child(3) .fa-check {
    /* color: #ffffff; */
}
/**/

.price-table tr.price-table-head td {
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    text-transform: uppercase;
}
.price-table tr.price-table-head {
    background-color: #7eb022;
    color: #FFFFFF;
}


.price-table tr td.subtitle {
    font-weight: bold;
    font-size: 16px !important;
}

.price-table td.price {
    color: rgb(93, 120, 46);
    padding: 16px 24px;
    font-size: 31px;
    font-weight: 700;
}
.price-table td.price:nth-child(5) {
    color: rgb(93, 120, 46);
    padding: 16px 24px;
    font-size: 22px;
    font-weight: 700;
}
.price-table td.price a {
    background-color: #7eb022;
    color: #FFFFFF;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 64px;
}
.price-table td.price-table-popular {
    border-top: 3px solid #7eb022;
    text-align: center;
    color: #7eb022;
    text-transform: uppercase;
    font-size: 16px;
    padding: 12px 48px;
    font-weight: 700;
}
.price-table .price-blank {
    background-color: #fafafa;
    border: 0 none;
}

.price-table svg {
    width: 90px;
    fill: #5336ca;
}

/* Menu style */
a.ant-dropdown-trigger {
    position: absolute;
    right: 40px;
    cursor: pointer;
}

.menu_account .ant-dropdown-menu-item:last-child{
    /* Your CSS properties here */
    color: red; /* Example property */
}